/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $mainBurger  = $("#main_burger");
        var $mainNav    = $(".nav-primary");
        var $brand      = $(".brand");
        $mainBurger.on("click", function(e) {
          $mainBurger.toggleClass("is-active");
          // Do something else, like open/close menu
          $brand.toggleClass("show");
          $mainNav.toggleClass("show");
        });

        var $subBurger  = $("#sub_burger");
        var $preNav  = $("#menu-preheader-navigation");
        $subBurger.on("click", function(e) {
          $subBurger.toggleClass("is-active");
          // Do something else, like open/close menu
          $preNav.toggleClass("showsub");
        });

        // progressive/responsive nav for preheader
        // from: http://jsfiddle.net/abhitalks/860LzgLL/
        /*
        var elemWidth, 
            elemHeight,
            fitCount, 
            fixedWidth = 120, 
            fixedHeight = 41, 
            $preheaderMenu = $("#menu-preheader-navigation"), 
            $collectedSet;

        function collect() {
          elemWidth = $preheaderMenu.width();
          elemHeight = $preheaderMenu.height();
          fitCount = Math.floor(elemHeight / fixedHeight) - 1;
          // fitCount = Math.floor(elemWidth / fixedWidth) - 1;
          // $collectedSet = $preheaderMenu.children(":gt(" + fitCount + ")");
          $collectedSet = $preheaderMenu.children(":gt(0)");
          $("#preheader-submenu").empty().append($collectedSet.clone());

          console.log(fitCount);
          console.log($collectedSet);
        }

        collect();
        $(window).resize(collect);
        */

        // searchbar in nav
        $(".menu-item.search-link").hover(
          function(){
            $("#searchbar").toggleClass('active');
          },
          function(){
            
            $("#searchbar").delay(8000).toggleClass('active');
          }
        );

        // lazy loading on images
        var bLazy = new Blazy({ 
          success: function(ele){
            // Image has loaded
            // Do your business here
            console.log("loaded.");
          }, 
          error: function(ele, msg){
            if(msg === 'missing'){
              // Data-src is missing
              console.log("missing.");
            }
            else if(msg === 'invalid'){
              // Data-src is invalid
              console.log("invalid.");
            }  
          }
        });

        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname){
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $('html, body').animate({
                // scrollTop: (target.offset().top - 70)
                scrollTop: (target.offset().top)
              }, 1000, "easeInOutExpo");
              // $mainBurger.toggleClass("is-active");
              return false;
            }
          }
        });


        // lazy load carousel
        $('.carousel.lazy-load').on('slide.bs.carousel', function (e) {
          var image = $(e.relatedTarget).find('img[data-src]');
          image.attr('src', image.data('src'));
          image.removeAttr('data-src');
        });

        Fancybox.bind("[data-fancybox]", {
          // Your custom options
        });

        Fancybox.bind('[data-fancybox="gallery"]', {
          // Your custom options
          Thumbs: false,
          Toolbar: {
            display: {
              left: [],
              middle: [
                // "zoomIn",
                // "zoomOut",
                // "toggle1to1",
                // "rotateCCW",
                // "rotateCW",
                // "flipX",
                // "flipY",
              ],
              right: [
                // "slideshow", 
                // "thumbs", 
                "close"
              ],
            },
          },
        });

        // drawer nav
        $(".drawer-nav--item").on("click", function(){

          // close any open containers
          $(".drawer-nav--item").not($(this)).removeClass("active");
          $(".drawer-content").removeClass("active");
          
          $content = $(this).data("content");
          $contentID = $("#" + String($content));

          if ($(this).hasClass('active')){
            
            $(this).removeClass('active');
            // $contentID.slideToggle();
            $contentID.removeClass('active');

          } else {

            $(this).addClass('active');
            // $contentID.slideToggle();
            $contentID.addClass('active');

          }

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'post_template_template_design_awards': {
      init: function() {
        // JavaScript to be fired on this page
        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    //
    // Allied Members archive page.
    //
    'post_type_archive_allied_members': {
      init: function() {
        // JavaScript to be fired on the home page

        var $filterToggle = $(".filter-toggle--button");

        $filterToggle.on('click', function(){
          
          $(this).toggleClass('active');
          
          $("#filter").slideToggle("fast");
        });

        $("#filter-toggle").click(function(e) {
          e.stopPropagation(); //stops click event from reaching document
        });

        $(document).on('click', function() {
          
          if ($filterToggle.hasClass('active')){

            $($filterToggle).toggleClass('active');

            $("#filter").slideToggle("fast"); //click came from somewhere else
          }
        });

        /*---------------------------------------*/
        // Begin AJAX filtering
        /*---------------------------------------*/

        // Set up our vars
        var $inputItem = $('.filter-choices .custom-control input');
        var $arr_areasofwork = []; // Array For checked region
        
        // Function to remove array data if more is added via click
        function clearArray() {
          $arr_areasofwork = []; // Array For sdg-type
        }
               
        $inputItem.on('click', function() {

          // Not sure if we need this
          if ($(this).hasClass('active')){
            $(this).removeClass('active');
          } else {
            $(this).addClass('active');
          }

          // remove previous array data
          clearArray();

          // Now, add checked items to their respective arrays

          $("input[name='areas-of-work[]']:checked").each( function () {
            var tmp = $(this).val();
            $arr_areasofwork.push(tmp);
          });
          
          // Show reults in console
          console.log($(this).attr('name'));
          console.log($(this).val());
          console.log($arr_areasofwork);

          // ajaxify
          $.ajax({
            url: '/wp-admin/admin-ajax.php',
            // url: '/clients/aiaaustin/wp-admin/admin-ajax.php',
            dataType: "html",
            type: "POST",
            data:{
              "filter_areasofwork"  : $arr_areasofwork,
              "action"              : "filter_allied_members" // name of function() in functions.php
            },
            success: function(res) {
              // $('.sdg-cards').append(res);
              $('.allied-mbr-cards').html(res);
              // console.log(res);

              // re-init lazy loading
              // var bLazy = new Blazy({ 
              //   success: function(ele){
              //   // Image has loaded
              //   // Do your business here
              //   console.log("loaded.");
              //   }, 
              //   error: function(ele, msg){
              //     if(msg === 'missing'){
              //       // Data-src is missing
              //       console.log("missing.");
              //     }
              //     else if(msg === 'invalid'){
              //       // Data-src is invalid
              //       console.log("invalid.");
              //     }
              //   }
              // });
            }
          });
        });
        /*---------------------------------------*/
        // END AJAX filtering
        /*---------------------------------------*/

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    //
    // Architects archive page.
    //
    'post_type_archive_architects': {
      init: function() {
        // JavaScript to be fired on the about us page

        var $filterToggle = $(".filter-toggle--button");

        $filterToggle.on('click', function(){
          
          $(this).toggleClass('active');
          
          $("#filter").slideToggle("fast");
        });

        $("#filter-toggle").click(function(e) {
          e.stopPropagation(); //stops click event from reaching document
        });

        $(document).on('click', function() {
          
          if ($filterToggle.hasClass('active')){

            $($filterToggle).toggleClass('active');

            $("#filter").slideToggle("fast"); //click came from somewhere else
          }
        });

        /*---------------------------------------*/
        // Begin AJAX filtering
        /*---------------------------------------*/

        // Set up our vars
        var $inputItem = $('.filter-choices .custom-control input');
        var $arr_proptype = []; // Array For checked region
        var $arr_size = []; // Array For checked region
        var $arr_areasofwork = []; // Array For checked region
        
        // Function to remove array data if more is added via click
        function clearArray() {
          $arr_proptype = []; // Array For provinces
          $arr_size = []; // Array For sdg-type
          $arr_areasofwork = []; // Array For sdg-type
        }
               
        $inputItem.on('click', function() {

          // Not sure if we need this
          if ($(this).hasClass('active')){
            $(this).removeClass('active');
          } else {
            $(this).addClass('active');
          }

          // remove previous array data
          clearArray();

          // Now, add checked items to their respective arrays

          $("input[name='property-type[]']:checked").each( function () {
            var tmp = $(this).val();
            $arr_proptype.push(tmp);
          });

          $("input[name='size[]']:checked").each( function () {
            var tmp = $(this).val();
            $arr_size.push(tmp);
          });

          $("input[name='areas-of-work[]']:checked").each( function () {
            var tmp = $(this).val();
            $arr_areasofwork.push(tmp);
          });
          
          // Show reults in console
          console.log($(this).attr('name'));
          console.log($(this).val());
          console.log($arr_proptype);
          console.log($arr_size);
          console.log($arr_areasofwork);

          // ajaxify
          $.ajax({
            url: '/wp-admin/admin-ajax.php',
            // url: '/clients/aiaaustin/wp-admin/admin-ajax.php',
            dataType: "html",
            type: "POST",
            data:{
              "filter_proptype"     : $arr_proptype,
              "filter_size"         : $arr_size,
              "filter_areasofwork"  : $arr_areasofwork,
              "action"              : "filter_architects" // name of function() in functions.php
            },
            success: function(res) {
              // $('.sdg-cards').append(res);
              $('.architect-cards').html(res);
              // console.log(res);

              // re-init lazy loading
              var bLazy = new Blazy({ 
                success: function(ele){
                // Image has loaded
                // Do your business here
                console.log("loaded.");
                }, 
                error: function(ele, msg){
                  if(msg === 'missing'){
                    // Data-src is missing
                    console.log("missing.");
                  }
                  else if(msg === 'invalid'){
                    // Data-src is invalid
                    console.log("invalid.");
                  }
                }
              });
            }
          });
        });
        /*---------------------------------------*/
        // END AJAX filtering
        /*---------------------------------------*/

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
